:root{
  --font-size-medium: 22px;
  --color-neutral-0: #ffffff;
  --color-dull: #ebebeb;
  --color-neutral-2: #ededed;
  --color-neutral-9: #000;
  --color-body: #575757;
  --space-soft: 4px;
  --space-base: 8px;
  --space-m: 16px;
  --space-large: 32px;
  --color-primary: #777CFA;
  --color-primary-light: #E9EAFF;
  --color-secondary: #FEA63D;
  --color-secondary-light: #FFD39C;
  --background-mustered-gradient: linear-gradient(180deg, #FEBA67 0%, #FE9E2D 100%);
  --yellow-overlay: rgba(254, 185, 100, 0.57);
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--color-body);
}
h1{
  font-family: "Inter", sans-serif;
}
a{
  text-decoration: none;
}
.display-flex{
  display: flex;
}
.flex-wrap{
  flex-wrap: wrap;
}
.align-items-center{
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-space-between{
  justify-content: space-between;
}
.display-block{
  display: block;
}
.font-size-medium{
  font-size: var(--font-size-medium);
}
.font-weight-bold{
  font-weight: bold;
}

.background-neutral-0{
  background: var(--color-neutral-0);
}
.background-primary{
  background-color: var(--color-primary);
}
.background-primary-light{
  background-color: var(--color-primary-light);
}
.background-secondary-light {
  background-color: var(--color-secondary-light);
}
.background-dull{
  background-color: var(--color-dull);
}
.background-voilet-lightest{
  background-color: #f3f3ff;
}
.mustuerd-line:before {
  content: "";
  background-color: var(--color-secondary);
  height: 2px;
  position: absolute;
  width: 40%;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.text-neutral-9{
  color: var(--color-neutral-9);
}
.text-neutral-0{
  color: var(--color-neutral-0);
}
.text-primary{
  color: var(--color-primary);
}
.text-secondary{
  color: var(--color-secondary);
}
.text-secondary-light{
 color: var(--color-secondary-light);
}
.border-bottom-dashed{
  border-bottom: 1px dashed currentcolor;
}
/**Margin Padding**/
.padding-base{
  padding: var(--space-base);
}
.padding-medium{
  padding: var(--space-m);
}
.padding-y-base{
  padding-top: var(--space-base);
  padding-bottom: var(--space-base)
}
.padding-y-l{
  padding-top: var(--space-large);
  padding-bottom: var(--space-large)
}
.padding-x-medium{
  padding-left: var(--space-m);
  padding-right: var(--space-m);
}
.padding-y-large{
  padding-top: var(--space-large);
  padding-bottom: var(--space-large);
}
.padding-y-m{
  padding-top: var(--space-m);
  padding-bottom: var(--space-m);
}
.padding-right-m{
  padding-right: var(--space-m);
}
.padding-right-large{
  padding-right: var(--space-large);
}
.padding-l{
  padding: var(--space-large);
}
.margin-medium{
  margin: var(--space-m);
}
.margin-l{
  margin: var(--space-large);
}
.margin-right-base{
   margin-right: var(--space-base);
}
.margin-right-l{
  margin-right: var(--space-large);
}
.margin-top-l{
  margin-top: var(--space-large);
}
.margin-bottom-l{
  margin-bottom: var(--space-large);
}
.margin-right-medium{
  margin-right: var(--space-m)
}
.margin-left-medium{
  margin-left: var(--space-m);
}
.margin-bottom-m{
  margin-bottom: var(--space-m);
}
.margin-base{
  margin:var(--space-base);
}
.margin-left-base{
  margin-left: var(--space-base);
}
.margin-bottom-base{
  margin-bottom: var(--space-base);
}
.margin-top-none{
  margin-top: 0px;
}
.margin-bottom-none{
  margin-bottom: 0px;
}
.position-relative{
  position: relative;
}
.full-height{
  height: 100%;
}
.semi-bold{
  font-weight: 600;
}
.full-width{
  width: 100%;
}
.YellowOverlay{
  background: var(--yellow-overlay);
}
.text-voilet{
  color: var(--color-primary);
}
.Banner{
 background-image: url('Assets/banner.jpg');
 background-size: cover;
}
.BannerContent{
  max-width: 1280px;
  margin: auto;
  padding: 3em;
  text-align: center;
}
.font-small{
  font-size: 12px;
}
/**Button**/
.btn{
  border-radius: var(--space-soft);
  padding: var(--space-base) var(--space-m);
  border: 0px;
  height: 45px;
  font-size: 14px;
}
.btn-primary{
  color: var(--color-neutral-0);
  background: var(--background-mustered-gradient);
}
.btn-secondary{
  background: var(--color-neutral-2);
  color: var(--color-secondary);
}

/***Navbar***/

.Navbar{
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: auto;
}
.Navigation{
  display: flex;
}
.Navigation a.active:before{
  content: "";
  background-color: var(--color-secondary);
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 3px;
}
/**Tab**/
.TabSection{
  max-width: 1280px;
  margin: auto;
}
.TabButton{
  color: #707070;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 8px 16px;
  margin: 8px;
  cursor: pointer;
}
.TabButton.active{
 background-color: var(--color-primary); 
 color: var(--color-neutral-0);
}
.TabContent{
  display: none;
}
.TabContent.active{
  display: block;
}
.TabImage{
  width: 420px;
  margin: auto;
  display: block;
}
.min-height-420{
  min-height: 420px;
}
.column-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.column-6{
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.column-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.column-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.voilet-line:before{
  content:"";
  background-color: var(--color-primary);
  height: 2px;
  position: absolute;
  width: 40%;
  left: 0;
  top:0;
  bottom: 0;
  margin: auto;
}
.secondary-line::before{
  content:"";
  background-color: #ffd199;
  height: 1px;
  position: absolute;
  width: 40%;
  left: 0;
  top:0;
  bottom: 0;
  margin: auto;
}
.theme-border-radius{
  border-radius: 30px 4px 30px 4px ;
}
.border-soft-neutral-4{
  border: 3px solid #ddd;
}
.border-right-m-neutral-0{
  border-right: var(--space-m) solid var(--color-neutral-0);
}
.border-right-m-primary{
  border-right: var(--space-m) solid var(--color-primary);
}
.InputField {
  width: 100%;
  height: 45px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.btn-large {
  height: 51px;
  font-size: 18px;
  border: 0px;
  padding: 0px 22px;
}

.App {
  text-align: center;
}
.z-index-99{
  z-index: 99;
}
.NewsletterSection, .FooterContent, .DoContent, .PolicyContent, .AchimentSection, .TestimonialSection{
  max-width: 1280px;
  margin: auto;
}
@media only screen and (min-width: 986px) and (max-width: 1280px) {
  .NewsletterSection, .FooterContent, .DoContent, .PolicyContent, .AchimentSection, .TestimonialSection{
    max-width: 90%;
    margin: auto;
  }
}
.border-bottom-neutral-0{
  border-bottom: 1px solid var(--color-neutral-0);
}
.BurgurMenu{
  display: none;
}
.MobileNav{
  display: none;
}
/**Media Query**/
.InvertImage{
  width: 100px;
  filter: invert(100%);
}
.quete{
  position: relative;
}
.quete::before{
  content: "";
  width: 20px;
  height: 20px;
  background-image: url('../src/Assets/quete.png');
  display: inline-block;
  background-size: cover;
  margin-right: 10px;
}
.Header{
 box-shadow: 0px 0px 16px -6px rgb(0 0 0 / 55%);
 z-index: 999;
}
.default-nav .Header{
  position: relative;
}
.sticky-nav .Header{
  position: sticky;
  top:0px;
  width: 100%;
}
.contact-icon{
  width: 26px;
  height: 26px;
  filter: invert(100%);
}
@media (max-width: 1025px){
  .column-3 ,.column-4, .column-6, .column-8 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
  }
  .Navigation{
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
    background-color: var(--color-neutral-0);
    display: block;
    width: 300px;
    box-shadow: 0px 0px 20px -6px rgb(0 0 0 / 30%);
  }
  .Navigation.active{
    left: 0px;
  }
  .BurgurMenu{
    width: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #ffcd92;
    padding: 15px;
    z-index: 99;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 23px -12px #000;
    cursor: pointer;
  }
  .Navigation a{
    border-bottom: 1px solid #f1f1f1;
  }
  .Navigation a.active::before{
    left: 0;
    right: auto;
    height: 100%;
    width: 3px;
  }
  .Navigation a.padding-y-base {
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
  }
 .NavButton{
  padding: 20px 20px 0px 0px;
 }
 .NavButton a{
  width: 100%;
  text-align: center;
 }
 .MobileNav{
  width: 23px;
  height: 35px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  opacity: 0.4;
  cursor: pointer;
 }
}